import clsx from 'clsx';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useIntl, Link } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Input from '../components/input';
import InputPhone from '../components/input-phone';
import CountrySelect from '../components/country-select';
import DatePicker from '../components/date-picker';
import Button from '../components/button';
import Checkbox from '../components/checkbox';

import { pageLinks } from '../helpers/navigation';

import styles from '../styles/pages/become-ibp.module.scss';
import { getInstanceName } from '../helpers/utils';

const initValues = {
  country: '',
  username: '',
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  dateOfBirthday: '',
  city: '',
  address: '',
  postalCode: '',
  taxNumber: '',
  SSN: '',
  taxFillingNumber: '',
  companyName: '',
  dateFound: '',
  companyRegistrationNumber: '',
  vat: '',
  SIRETNumber: '',
  IntraCommunityVATNumber: '',
  coApplicantFirstName: '',
  coApplicantLastName: '',
};

const BecomeIBPPage = () => {
  const intl = useIntl();
  const joinOurFamily = intl.formatMessage({ id: 'become_ibp.joinOurFamily' });
  const becomeIBP = intl.formatMessage({ id: 'become_ibp.become_ibp' });
  const pleaseFillIn = intl.formatMessage({ id: 'become_ibp.please_fill_in' });
  const countryOfResidence = intl.formatMessage({ id: 'become_ibp.countryOfResidence' });
  const username = intl.formatMessage({ id: 'become_ibp.username' });
  const email = intl.formatMessage({ id: 'become_ibp.email' });
  const personalInformation = intl.formatMessage({ id: 'become_ibp.personal_information' });
  const firstName = intl.formatMessage({ id: 'become_ibp.first_name' });
  const middleName = intl.formatMessage({ id: 'become_ibp.middle_name' });
  const lastName = intl.formatMessage({ id: 'become_ibp.last_name' });
  const phone = intl.formatMessage({ id: 'become_ibp.phone' });
  const dateOfBirthday = intl.formatMessage({ id: 'become_ibp.date_of_birthday' });
  const city = intl.formatMessage({ id: 'become_ibp.city' });
  const address = intl.formatMessage({ id: 'become_ibp.address' });
  const postalCode = intl.formatMessage({ id: 'become_ibp.postal_code' });
  const ZIPpostalCode = intl.formatMessage({ id: 'become_ibp.zip' });
  const code = intl.formatMessage({ id: 'become_ibp.code' });
  const taxFillingNumber = intl.formatMessage({ id: 'become_ibp.tax_filling_number' });
  const taxFillingNumberShort = intl.formatMessage({ id: 'become_ibp.tax_filling_number_short' });
  const SSN = intl.formatMessage({ id: 'become_ibp.SSN' });
  const number = intl.formatMessage({ id: 'become_ibp.number' });
  const company = intl.formatMessage({ id: 'become_ibp.company' });
  const companyInfo = intl.formatMessage({ id: 'become_ibp.company_info' });
  const companyName = intl.formatMessage({ id: 'become_ibp.company_name' });
  const dateOfIncorporationOfCompany = intl.formatMessage({
    id: 'become_ibp.dateOfIncorporationOfCompany',
  });
  const termsOfUse = intl.formatMessage({ id: 'become_ibp.terms_of_use' });
  const privacyPolicy = intl.formatMessage({ id: 'become_ibp.privacy_policy' });
  const IBIAgreement = intl.formatMessage({ id: 'IBIAgreement' });
  const policiesAndProcedures = intl.formatMessage({ id: 'policies_and_procedures' });
  const gdprPolicy = intl.formatMessage({ id: 'gdpr_policy' });
  const financialRewardsPlan = intl.formatMessage({ id: 'financialRewardsPlan' });
  const corporateRequirements = intl.formatMessage({ id: 'corporateRequirements' });
  const getStarted = intl.formatMessage({ id: 'get_started' });
  const enterYourLocal = intl.formatMessage({ id: 'become_ibp.enter_your_local' });
  const coApplicant = intl.formatMessage({ id: 'become_ibp.coApplicant' });
  const coApplicantOptional = intl.formatMessage({ id: 'become_ibp.coApplicantOptional' });
  const coApplicantFirstName = intl.formatMessage({ id: 'become_ibp.coApplicantFirstName' });
  const coApplicantLastName = intl.formatMessage({ id: 'become_ibp.coApplicantLastName' });
  const byClickingYouConfirm = intl.formatMessage({ id: 'become_ibp.byClickingYouConfirm' });
  const byClickingYouConsent = intl.formatMessage({ id: 'become_ibp.byClickingYouConsent' });
  const and = intl.formatMessage({ id: 'and' });
  const companyRegistrationNumber = intl.formatMessage({
    id: 'become_ibp.company_registration_number',
  });
  const SIRETNumber = intl.formatMessage({ id: 'become_ibp.SIRETNumber' });
  const IntraCommunityVATNumber = intl.formatMessage({ id: 'become_ibp.IntraCommunityVATNumber' });

  const emailTip = 'emailTip';
  const firstNameTip = 'firstNameTip';
  const lastNameTip = 'lastNameTip';
  const phoneTip = intl.formatMessage({ id: 'become_ibp.byClickingYouConsentToReceive' });
  const companyDateOfIncorporationTip = 'companyDateOfIncorporationTip';
  const coApplicantFirstNameTip = 'coApplicantFirstNameTip';
  const coApplicantLastNameTip = 'coApplicantLastNameTip';

  const [values, setValues] = useState(initValues);
  const [showCompany, setShowCompany] = useState(false);
  const [showCoApplicant, setShowCoApplicant] = useState(false);
  const [agreements, setAgreements] = useState({ confirmation: false, consenting: false });

  const toggleShowCompany = () => {
    setShowCompany((prevState) => !prevState);
  };

  const toggleShowshowCoApplicant = () => {
    setShowCoApplicant((prevState) => !prevState);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
  };

  const onChange = ({ target }) => {
    const { name, value } = target;

    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onCountrySelect = ({ value }) => {
    setValues((prevState) => ({ ...prevState, country: value }));
  };

  const createDateSetter = (key) => (value) => {
    setValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const createAgreementsToggle = (key) => () => {
    setAgreements((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  const USA = values.country === 'us';
  const Canada = values.country === 'ca';
  const France = values.country === 'fr';

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.becomeIBP' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout>
        <div className="container">
          <div className={styles.becomeIbp}>
            <div className={styles.header}>
              <h2 className={styles.title}>{joinOurFamily}</h2>
              <p className={styles.subtitle}>{becomeIBP}</p>
              <p className={styles.cta}>{pleaseFillIn}</p>
            </div>
            <form onSubmit={onFormSubmit} className={styles.form}>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <CountrySelect
                    id="country"
                    value={values.country}
                    label={countryOfResidence}
                    placeholder={countryOfResidence}
                    onChange={onCountrySelect}
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    id="username"
                    name="username"
                    value={values.username}
                    label={username}
                    placeholder={username}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    label={email}
                    placeholder={email}
                    onChange={onChange}
                    rightSideLink={
                      <i className={clsx('icon-info', styles.tooltipIcon)} data-tip={emailTip} />
                    }
                  />
                </div>
              </div>
              <div className={styles.divider} />
              <p className={styles.formTitle}>{personalInformation}</p>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX3)}>
                  <Input
                    fullwidth
                    required
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    label={firstName}
                    placeholder={firstName}
                    onChange={onChange}
                    rightSideLink={
                      <i
                        className={clsx('icon-info', styles.tooltipIcon)}
                        data-tip={firstNameTip}
                      />
                    }
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX3)}>
                  <Input
                    fullwidth
                    id="middleName"
                    name="middleName"
                    value={values.middleName}
                    label={middleName}
                    placeholder={middleName}
                    onChange={onChange}
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX3)}>
                  <Input
                    fullwidth
                    required
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    label={lastName}
                    placeholder={lastName}
                    onChange={onChange}
                    rightSideLink={
                      <i className={clsx('icon-info', styles.tooltipIcon)} data-tip={lastNameTip} />
                    }
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <InputPhone
                    fullwidth
                    required
                    id="phone"
                    name="phone"
                    value={values.phone}
                    country={values.country}
                    label={phone}
                    placeholder={phone}
                    onChange={onChange}
                    rightSideLink={
                      <i className={clsx('icon-info', styles.tooltipIcon)} data-tip={phoneTip} />
                    }
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <DatePicker
                    fullwidth
                    required
                    id="dateOfBirthday"
                    value={values.dateOfBirthday}
                    label={dateOfBirthday}
                    onChange={createDateSetter('dateOfBirthday')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    required
                    id="city"
                    name="city"
                    value={values.city}
                    label={city}
                    placeholder={city}
                    onChange={onChange}
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    required
                    id="address"
                    name="address"
                    value={values.address}
                    label={address}
                    placeholder={address}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    required
                    id="postalCode"
                    name="postalCode"
                    value={values.postalCode}
                    label={USA || Canada ? ZIPpostalCode : postalCode}
                    placeholder={code}
                    onChange={onChange}
                  />
                </div>
                <div className={clsx(styles.col, styles.mdX2)}>
                  <Input
                    fullwidth
                    required
                    id={USA || Canada || France ? 'SSN' : 'taxNumber'}
                    name={USA || Canada || France ? 'SSN' : 'taxNumber'}
                    value={USA || Canada || France ? values.SSN : values.taxNumber}
                    label={USA || Canada || France ? SSN : taxFillingNumber}
                    placeholder={number}
                    onChange={onChange}
                    rightSideLink={
                      <i
                        className={clsx('icon-info', styles.tooltipIcon)}
                        data-tip={enterYourLocal}
                      />
                    }
                  />
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.showCompany}>
                <Checkbox onChange={toggleShowCompany} checked={showCompany}>
                  <span className={styles.toggleLabel}>
                    <i className="icon-briefcase" />
                    {company}
                  </span>
                </Checkbox>
              </div>
              {showCompany && (
                <>
                  <p className={styles.formTitle}>{companyInfo}</p>
                  <div className={styles.row}>
                    <div className={clsx(styles.col, styles.mdX2)}>
                      <Input
                        fullwidth
                        id="companyName"
                        name="companyName"
                        value={values.companyName}
                        label={companyName}
                        placeholder={companyName}
                        onChange={onChange}
                        rightSideLink={
                          <i
                            className={clsx('icon-info', styles.tooltipIcon)}
                            data-tip={companyDateOfIncorporationTip}
                          />
                        }
                      />
                    </div>
                    <div className={clsx(styles.col, styles.mdX2)}>
                      <DatePicker
                        id="dateFound"
                        value={values.dateFound}
                        label={dateOfIncorporationOfCompany}
                        onChange={createDateSetter('dateFound')}
                        rightSideLink={
                          <i
                            className={clsx('icon-info', styles.tooltipIcon)}
                            data-tip={enterYourLocal}
                          />
                        }
                      />
                    </div>
                  </div>
                  {(USA || Canada) && (
                    <div className={styles.row}>
                      <div className={clsx(styles.col, styles.mdX2)}>
                        <Input
                          fullwidth
                          required
                          id="companyRegistrationNumber"
                          name="companyRegistrationNumber"
                          value={values.companyRegistrationNumber}
                          label={companyRegistrationNumber}
                          placeholder={number}
                          onChange={onChange}
                        />
                      </div>

                      {Canada && (
                        <div className={clsx(styles.col, styles.mdX2)}>
                          <Input
                            fullwidth
                            required
                            id="taxFillingNumberShort"
                            name="taxFillingNumberShort"
                            value={values.taxFillingNumber}
                            label={taxFillingNumberShort}
                            placeholder={number}
                            onChange={onChange}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {France && (
                    <div className={styles.row}>
                      <div className={clsx(styles.col, styles.mdX2)}>
                        <Input
                          fullwidth
                          required
                          id="SIRETNumber"
                          name="SIRETNumber"
                          value={values.SIRETNumber}
                          label={SIRETNumber}
                          placeholder={number}
                          onChange={onChange}
                        />
                      </div>

                      <div className={clsx(styles.col, styles.mdX2)}>
                        <Input
                          fullwidth
                          required
                          id="IntraCommunityVATNumber"
                          name="IntraCommunityVATNumber"
                          value={values.IntraCommunityVATNumber}
                          label={IntraCommunityVATNumber}
                          placeholder={number}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className={styles.divider} />
              <div className={styles.showCompany}>
                <Checkbox onChange={toggleShowshowCoApplicant} checked={showCoApplicant}>
                  <span className={styles.toggleLabel}>
                    <i className="icon-add-user" />
                    {coApplicant}
                  </span>
                </Checkbox>
              </div>
              {showCoApplicant && (
                <>
                  <p className={styles.formTitle}>{coApplicantOptional}</p>
                  <div className={styles.row}>
                    <div className={clsx(styles.col, styles.mdX2)}>
                      <Input
                        fullwidth
                        id="coApplicantFirstName"
                        name="coApplicantFirstName"
                        value={values.coApplicantFirstName}
                        label={coApplicantFirstName}
                        placeholder={coApplicantFirstName}
                        onChange={onChange}
                        rightSideLink={
                          <i
                            className={clsx('icon-info', styles.tooltipIcon)}
                            data-tip={coApplicantFirstNameTip}
                          />
                        }
                      />
                    </div>
                    <div className={clsx(styles.col, styles.mdX2)}>
                      <Input
                        fullwidth
                        id="coApplicantLastName"
                        name="coApplicantLastName"
                        value={values.coApplicantLastName}
                        label={coApplicantFirstName}
                        placeholder={coApplicantLastName}
                        onChange={onChange}
                        rightSideLink={
                          <i
                            className={clsx('icon-info', styles.tooltipIcon)}
                            data-tip={coApplicantLastNameTip}
                          />
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <div className={styles.divider} />
              <div className={styles.row}>
                <div className={clsx(styles.col, styles.agreements)}>
                  <Checkbox
                    checked={agreements.confirmation}
                    onChange={createAgreementsToggle('confirmation')}
                  />
                  <p>
                    {byClickingYouConfirm}
                    &nbsp;
                    <Link to={pageLinks.privacyPolicy} className={styles.agreementLink}>
                      {privacyPolicy}
                    </Link>
                    &nbsp;
                    <Link to={pageLinks.terms} className={styles.agreementLink}>
                      {termsOfUse}
                    </Link>
                    &nbsp;
                    <Link to="/" className={styles.agreementLink}>
                      {IBIAgreement}
                    </Link>
                    &nbsp;
                    <Link to={pageLinks.policiesAndProcedures} className={styles.agreementLink}>
                      {policiesAndProcedures}
                    </Link>
                    &nbsp;
                    <Link to={pageLinks.gdprPolicy} className={styles.agreementLink}>
                      {gdprPolicy}
                    </Link>
                    &nbsp;
                    <Link to="/" className={styles.agreementLink}>
                      {financialRewardsPlan}
                    </Link>
                    &nbsp;
                    {and}
                    &nbsp;
                    <Link to="/" className={styles.agreementLink}>
                      {corporateRequirements}
                    </Link>
                    <span className="asterisk">*</span>
                    <span>.</span>
                  </p>
                </div>
                <div className={clsx(styles.col, styles.agreements)}>
                  <Checkbox
                    checked={agreements.consenting}
                    onChange={createAgreementsToggle('consenting')}
                  />
                  <p>
                    {byClickingYouConsent}
                    <span className="asterisk">*</span>
                    <span>.</span>
                  </p>
                </div>
              </div>
              <div className={styles.action}>
                <div className={styles.submit}>
                  <Button
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                    withArrow
                    disabled={!agreements.confirmation && !agreements.consenting}
                  >
                    {getStarted}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
      <ReactTooltip className={styles.tooltip} />
    </>
  );
};

export default BecomeIBPPage;
